#profile-container-content-logged-in {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

#profile-container-content-logged-out {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.profile-container button {
  margin-left: -9px;
  cursor: pointer;
  font-size: 16pt;
  background-color: transparent;
  color: black;
  padding: 14px 16px;
  border-radius: 50px;
  border: none;
}

.profile-container button:hover {
  background-color: rgb(240, 240, 240);
}

.profile-container button:active {
  color: green !important;
}

.profile-dropdown {
  position: fixed;
  top: -16px;
  left: -1px;
  height: 100%;
  width: 200px;
  padding: 40px 50px;
  background-color: #FFFFFF;
  box-shadow: 10px 0px 50px #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#dropdown-user-icon {
  background-color: rgb(219, 219, 219);
  padding: 14px 16px;
  border-radius: 50px;
}

.dropdown-icon-spacer {
  margin-right: 12px;
}

#dropdown-sign-out-link {
  color: #787878;
  cursor: pointer;
}

#dropdown-sign-out-link:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 22px 22px;
}

#nav-logo-text {
  font-size: 30px;
  background-color: white;
  font-weight: 300;
}

#nav-right-hamburger-and-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

#logo-eats-text {
  font-weight: 500;
}

#nav-left-other-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.shopping-cart {
  cursor: pointer;
  display: flex;
  font-size: 14pt;
  gap: 8px;
  align-items: center;
  color: white;
  background-color: black;
  border-radius: 50px;
  border: 2px solid black;
  padding: 12px 22px;
}

.shopping-cart:hover {
  background-color: green;
  border: 2px solid green;
}

.shopping-cart:active {
  border: 2px solid white !important;
}

.shopping-cart i {
  color: #FFFFFF;
}

.shopping-cart div {
  color: #FFFFFF;
}

#logInSignUpNavDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.navModalButton button {
  display: flex;
  background-color: white;
  border: none;
  display: flex;
  font-size: 14pt;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  color: white;
  background-color: black;
  border-radius: 50px;
  border: 2px solid black;
  padding: 12px 22px;
}

.navModalButton button:hover {
  cursor: pointer;
}


#logInSignUpNavDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.navModalButton button {
  display: flex;
  background-color: white;
  border: none;
  display: flex;
  font-size: 14pt;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  color: white;
  background-color: black;
  border-radius: 50px;
  border: 2px solid black;
  padding: 12px 22px;
}

.navModalButton button:hover {
  background-color: green;
  border: 2px solid green;
}
.navModalButton button:active {
  border: 2px solid white;
}

#theBurgerLogo {
  margin-left: 1px;
  font-size: 33px;
}

#theBurgerLogo:active {
  color: green !important;
}

#restaurantsNavLinkDiv {
  display: flex;
  /* background-color: white ; */
  margin-right: -10px;
  border: none;
  display: flex;
  font-size: 14pt;
  align-items: center;
  color: white;
  background-color: black;
  border-radius: 50px;
  border: 2px solid black;
  padding: 12px 22px;
}

#restaurantsNavLinkDiv:hover {
  background-color: green;
  border: 2px solid green !important;
}

#restaurantsNavLinkDiv:active {
  border: 2px solid white !important;
}
