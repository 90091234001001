#menu-item-owner-card {
    width: 300px;
    padding: 8px 8px 10px 8px;
    display: flex;
    flex-direction: column;
}

#menu-item-owner-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#menu-item-owner-info-col-1 {
    display: flex;
    flex-direction: column;
}

#menu-item-owner-info-col-2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#menu-item-owner-card:hover {
    box-shadow: 0px 4px 15px #e5e5e5;
}

#menu-item-owner-img {
    background-color: #d2d2d2;
    width: 300px;
    height: 190px;
    margin-right: 15px;
    object-fit: cover;
    border-radius: 7px;
}

#menu-item-owner-name {
    color: black;
    font-size: 16px;
    margin: 2px 0;
}

#menu-item-owner-price {
    color: black;
    font-size: 13.5px;
    font-weight: 100;
}

.menu-item-form-update-and-delete-modal-btns button {
    color: #000000;
    background-color: rgb(219, 219, 219);
    font-size: 17px;
    padding: 10px 12px;
    margin: 10px 0;
    border-radius: 7px;
    border: none;
}

.menu-item-form-update-and-delete-modal-btns button:hover {
    cursor: pointer;
    background-color: rgb(190, 190, 190);
}
