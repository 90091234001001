
.orders-page {
    width: 100%;
    display: flex;
    justify-content: center;
}

.orders-list {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    margin-top: none;
    gap: 10px;
    width: 70%;
}

/* .order-list h1 {
    margin-top: none;
} */

.orders-list-account {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order-list-account h1 {
    display: flex;
    margin-top: 0 !important;
}

.order-container {
    padding: 15px;
    height: fit-content;
    display: grid;
    grid-template-columns: 3fr 5fr 2fr;
    border-radius: 10px;
    cursor: pointer;
}

.order-container:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.order-image {
    width: auto;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    /* object-fit: cover !important; */
}

.order-image img {
    height: 100%;
    width: 100%;
    object-fit: cover !important;
}

.order-info {
    display: flex;
    flex-direction: column;
    /* padding: 5px; */
    padding-left: 10px;
    gap: 3px;
}

.order-info-name {
    font-size: 16pt;
    font-weight: bold;
}

.order-items-list {
    overflow-y: scroll;
}

.order-items-list div {
    padding-left: 5px;
    /* list-style: none; */
    font-size: 10pt;
    font-weight: lighter !important;
}

.reorder-div {
    display: flex;
    align-items: flex-start;
}

.reorder-div button {
    cursor: pointer;
    width: 100%;
    padding: 10px 15px;
    color: white;
    background-color: black;
    border: 2px solid black;
    border-radius: 5px;
}

.reorder-div button:hover {
    border: 2px solid white;
}
