.restaurant-card-top-div {
  border: 1px solid;
  border-color: grey;
  border-radius: 7px;
  padding: 10px 10px;
  margin: 24.42069px 0;
}

.restaurant-card-details-container-div {
  display: flex;
  flex-direction: row;
}

.restaurant-card-details-parent-div {
  position: relative;
  width: 100%;
  padding-left: 30px;
}

.restaurant-card-button-div {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  align-items: end;
  height: 55%;
}

.restaurant-card-button-div button {
  color: #FFFFFF;
  background-color: #000000;
  padding: 10px;
  margin: 10px 10px;
  /* width: 200px; */
  border-radius: 7px;
  border: 3px solid #000000;
  cursor: pointer;
}

.restaurant-card-name {
  font-size: xx-large;
}

.restaurant-card-avgRating {
  position: absolute;
  right: 0;
  padding: 0 10px;
}

.restaurant-card-cat-price {
  padding: 20px 0;
}

.restaurant-card-cat-price span {
  margin-right: 20px;
  font-size: larger;
}

.restaurant-card-streetAddress {
  padding: 5px 0;
  color: gray;
}

.restaurantImage {
  border-radius: 7px;
}
