#homePageBannerImage {
  width: 100%;
  height: 720px;
  /* height: auto; */
  max-width: 100%;
  display: block;
  margin-bottom: 0;
  object-fit: cover;
  /* border: 1px solid red; */
  /* z-index: 1; */
  position: relative;
  z-index: -2;
}

#homePageBannerTextOverlay {
  position: relative;
  z-index: -1;
  left: 45px;
  top: -430px;
  font-size: 55px;
  font-weight: 500;
}

.homePageImgBoxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 35px;
  margin: -60px 30px 0 30px;
}

.homePageImgs {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.homeSubheader {
  margin-top: 7px;
  font-size: 21px;
  font-weight: 400;
}

.homePageModal button {
  background-color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

#checkoutRestLink {
  color: black;
}

.homePageSubsectionLink1 {
  margin-left: -6px;
  margin-top: -15px;
}

.homePageSubsectionLink2 {
  margin-top: -15px;
}

.homePageSubsectionLink3 {
  margin-left: -6px;
  margin-top: -15px;
}
