#restaurantBannerImg {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.restaurantsContent {
    /* margin-left: 27px; */
    padding: 0 3%;
}

.exploreByCategory {
    margin-left: 5px;
}

.restCatHeader {
    margin-left: 5px;
}

.restaurantCardsDiv {
    margin-right: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.restaurantCardCatDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 25px;
    gap: 9px;
    row-gap: 26px;
}

.restCatButtons {
    color: #000000;
    background-color: rgb(235, 235, 235);
    border-radius: 50px;
    font-size: 20px;
    padding: 8px 18px;
}

.restCatButtons:hover {
    background-color: green;
    color: white;
}

.cat-button {
    cursor: pointer;
    color: #000000;
    background-color: rgb(235, 235, 235);
    border: 2px solid rgb(235, 235, 235);
    border-radius: 50px;
    font-size: 20px;
    padding: 8px 18px;
}

.cat-button:hover {
    background-color: rgb(220, 220, 220);
}

.cat-active {
    cursor: pointer;
    color: white;
    background-color: green;
    border: 2px solid green;
    border-radius: 50px;
    font-size: 20px;
    padding: 8px 18px;
}


#allCatButton {
    color: #000000;
    background-color: rgb(235, 235, 235);
    border-radius: 50px;
    font-size: 20px;
    padding: 8px 18px;
}

#allCatButton:hover {
    background-color: green;
    color: white;
}

#restaurantWholeDiv {
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
}

.restCardImage {
    height: 125px;
    width: 240px;
    background-color: #d2d2d2;
    border-radius: 12px;
    object-fit: cover;
}

#restaurantNameDiv {
    margin-top: 7px;
    font-size: 17px;
}

#restaurantCategoryPriceDiv {
    color: #8a8a8a;
    display: flex;
    flex-direction: row;
    font-size: 14px;
}

#restaurantAddressDiv {
    color: #8a8a8a;
    font-size: 14px;
}

#rest-back-to-cats {
    margin-left: 5px;
    margin-top: 20px;
    text-decoration: underline;
}
