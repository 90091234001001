#menu-item-delete-modal-outermost-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
}

#menu-item-delete-modal-centering-div {}

#menu-item-delete-modal-text {
    font-size: 26px;
    padding-bottom: 10px;
}

#menu-item-cancel-delete-btn {
    color: #000000;
    background-color: rgb(219, 219, 219);
    font-size: 17px;
    padding: 10px 12px;
    border-radius: 7px;
    border: none;
}

#menu-item-cancel-delete-btn:hover {
    background-color: rgb(190, 190, 190);
    cursor: pointer;
}

#menu-item-confirm-delete-btn {
    color: #FFFFFF;
    background-color: #991900de;
    font-size: 17px;
    padding: 10px 12px;
    border-radius: 7px;
    border: none;
}

#menu-item-confirm-delete-btn:hover {
    background-color: #711300de;
    cursor: pointer;
}
