
.account-page {
    margin-top: 30px;
    width: 100%;
}

#account-landing-outermost-box {
    margin: 0 30px;
    /* display: grid;
    grid-template-columns: 1fr 3fr; */
    display: flex;

}

#account-landing-left-sidebar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 15px;
    width: 25%;
    border-right: 1px solid lightgray;
}

#account-landing-left-sidebar button {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    font-size: 14pt;
    background-color: transparent;
    border: 0;
    padding: 10px;
    border-radius: 7px;
    /* text-align: left !important; */
}

.active-page {
    background-color: lightgray !important;
}

#account-landing-right-content {
    padding-left: 20px;
    width: 70%;
}

#account-landing-sign-out-btn {
    color: #787878;
    cursor: pointer;
}

#account-landing-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#account-landing-header {
    font-size: 35px;
    padding-bottom: 15px;
}

#account-landing-subheader {
    font-size: 24px;
    padding-bottom: 15px;
}

.account-landing-info-header {}

.account-landing-info {
    color: #787878;
    padding-bottom: 15px;
    border-bottom: 1px solid #dadada;
}
