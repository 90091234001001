#createReviewModal {
    border-radius: 10px;
}

#createReviewHeader {
    display: flex;
    justify-content: center;
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 7px;
}

.deleteModalHeader {
    display: flex;
    justify-content: center;
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 7px;
}

#createReviewTextArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 20px 5px 20px;
    width: 390px;
    height: 100px;
    border: 2px black solid;
    resize: none;
}

.createReviewStarsDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;
}

#createReviewStar {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.rev-star {
    font-size: 25px;
}

#starsText {
    padding-left: 7px;
}

#createReviewButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

#createReviewButton button {
    cursor: pointer;
}

#disabledButton {
    margin-top: 20px;
    margin-bottom: -15px;
    width: 300px;
    height: 40px;
    background-color: white;
    border-radius: 50px;
    color: grey;
    cursor: not-allowed;
}

#reviewLengthNote {
    display: flex;
    justify-content: center;
    font-size: 14px;
}

#notDisabledButton {
    margin-top: 20px;
    margin-bottom: -15px;
    width: 300px;
    height: 40px;
    border-radius: 50px;
    background-color: black;
    color: white;
}

#yesNoButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

#yesNoButtons button {
    cursor: pointer;
    background-color: white;
    font-size: 10pt;
    color: white;
    background-color: black;
    border-radius: 50px;
    margin-top: 15px;
    border: 2px solid black;
    padding: 8px 10px;
}

.review-error-text {
    color: rgba(255, 0, 0, 0.778);
    font-size: 12px;
    margin-left: 25px;
}

.review-star-error-text {
    color: rgba(255, 0, 0, 0.778);
    font-size: 12px;
}
