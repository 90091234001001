#logInModalDiv {
    width: 400px;
}


.login-label-text {
    margin-left: 5px;
}

.logInLabel {
    width: 94%;
}

#loginFormLoginButton {
    cursor: pointer;
}

#demoUserButton {
    display: flex;
    justify-content: center;
    width: 400px;
    color: #FFFFFF;
    background-color: black;
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #000000;
    margin-top: -15px;
    margin-bottom: 30px;
}

#demoUserButton:hover {
    cursor: pointer;
}

.login-button:hover {
    background-color: green !important;
    border: 3px solid green !important;
}

.login-button:active {
    border: 3px solid white !important;
}


#errorLogin {
    color: red;
}

#loginErrorDiv {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
