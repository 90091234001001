

.restaurant-banner-image {
    width: 100%;
    height: 160px;
    background-color: #d2d2d2;
    object-fit: cover;
    margin-bottom: 10px;
}

#rest-details-back-breadcrumb {
    padding-left: 9px;
    margin-bottom: 15px;
}

#back-restaurants-rd:hover {
    text-decoration: underline;
}

.restaurant-card {
    padding: 0 3%;
}

.restaurant-name {
    font-size: 40px;
    padding-left: 9px;
    padding-bottom: 9px;
}

.restaurant-details {
    font-size: 14px;
    padding-left: 9px;
    padding-bottom: 9px;
}

.restaurant-read-reviews {
    text-decoration: underline;
}

.restaurant-read-reviews:hover {
    cursor: pointer;
}

.restaurant-menu-item-card-holder {
    width: 300px;
}

.restaurant-menu-items-sections {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item-type-header {
    font-size: 26px;
    padding: 9px;
}

.reviewCon {
    display: flex;
    flex-direction: row;
    border: 1px solid red;
}
