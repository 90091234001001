.menu-item-card {
    width: 300px;
    padding: 8px 8px 10px 8px;
    display: flex;
    flex-direction: column;
}

.menu-item-card:hover {
    cursor: pointer;
    box-shadow: 0px 4px 15px #e5e5e5;
}

.menu-item-img {
    background-color: #d2d2d2;
    width: 300px;
    height: 190px;
    margin-right: 15px;
    border-radius: 7px;
}

.menu-item-name {
    color: black;
    font-size: 16px;
    margin: 2px 0;
}

.menu-item-price {
    color: black;
    font-size: 13.5px;
    font-weight: 100;
}
