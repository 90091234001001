#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
  border-radius: 7px;
  padding: 20px 40px;
}

#modal-content h1 {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
}

#modal-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
}

#modal-content form input {
  background-color: rgb(239, 239, 239);
  padding: 10px;
  border-radius: 7px;
  border: 3px solid #FFFFFF;
}

#modal-content form input:focus {
  border: 3px solid #000000;
}

#modal-content select {
  background-color: rgb(239, 239, 239);
  width: 175px;
  padding: 10px 10px 10px 5px;
  border-radius: 7px;
  border: 3px solid #FFFFFF;
}

#modal-content select:focus {
  border: 3px solid #000000;
}

#modal-content textarea {
  background-color: rgb(239, 239, 239);
  padding: 10px 10px 10px 5px;
  border-radius: 7px;
  border: 3px solid #FFFFFF;
}

#modal-content textarea:focus {
  border: 3px solid #000000;
}

#modal-content form button {
  color: #FFFFFF;
  background-color: #000000;
  padding: 10px;
  margin: 30px 0;
  border-radius: 7px;
  border: 3px solid #000000;
}

.modal-content form button:hover {
  cursor: pointer
}

#modal-content form button:disabled {
  background-color: gray;
}
