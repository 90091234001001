#loginPageOutermostBox {
    padding-left: 30px;
}

#loginPageHeader {
    font-size: 24px;
}

#loginPageOutermostBox input {
    background-color: rgb(239, 239, 239);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 7px;
    border: 3px solid #FFFFFF;
}

#loginPageOutermostBox input:focus {
    border: 3px solid #000000;
}

#loginPageButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#loginPageButtons button {
    color: #FFFFFF;
    background-color: #000000;
    padding: 10px;
    margin: 5px 0 0 3px;
    border-radius: 7px;
    border: 2px solid #000000;
    cursor: pointer
}

.login-button:hover {
    background-color: green;
    border: 2px solid green;
}

.login-button:active {
    border: 2px solid white;
}
