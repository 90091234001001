.menu-item-details-outermost-box {
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-item-details-centering-box {
    width: 70%;
}

.menu-item-details-card {
    width: 100%;
    padding-bottom: 20px;
    margin: 20px 0px;
    display: flex;
}

.menu-item-details-img {
    background-color: #d2d2d2;
    width: 300px;
    height: 300px;
    margin-right: 10px;
    object-fit: cover;
}

.menu-item-details-info {
    margin-left: 10px;
}

.menu-item-details-name {
    color: #000000;
    font-size: 34px;
    margin-bottom: 5px;
}

.menu-item-details-price {
    color: #000000;
    font-size: 18px;
    font-weight: 200;
    margin: 15px 0;
}

.menu-item-details-description {
    color: #7b7b7b;
    font-size: 14px;
    font-weight: 200;
    margin: 15px 0;
}

.menu-item-details-add-or-remove-button {
    color: #FFFFFF;
    background-color: #000000;
    font-size: 17px;
    padding: 12px;
    margin: 10px 0;
    border-radius: 7px;
    border: 3px solid #000000;
}

.menu-item-details-add-or-remove-button:hover {
    cursor: pointer
}

.back-restaurants-mi:hover {
    cursor: pointer;
    text-decoration: underline;
}
