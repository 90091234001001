/* TODO Add site wide styles */

.restaurantImage {
    width: 300px;
    height: 300px;
    object-fit: cover;
}

/* FOOTER */

#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 210px;
}

#footer-container {
    z-index: -1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 210px;
}
