#signUpModalDiv {
    width: 400px;
}

#signUpModalDiv form {
    margin-top: 25px;
}

.signup-label-text {
    margin-left: 5px;
}

.signUpLabel {
    background-color: red;
    width: 94%;
}

#signupModalSignupButton:hover {
    cursor: pointer;
    background-color: green;
    border: 3px solid green !important;
}

#signupModalSignupButton:active {
    cursor: pointer;
    border: 3px solid green !important;
}

#signupModalSignupButton:disabled {
    cursor: default;
    background-color: grey;
    border: 3px solid grey !important;
}

.error-message {
    color: red;
    font-size: 12px;
    margin: 5px;
}
